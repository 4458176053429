import React from 'react';

const PrivacyPolicyScreen = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>
        Welcome to EarnFlix, your gateway to earning income while enjoying your favorite videos! Before you proceed, please take a moment to review our Privacy Policy to understand how we collect, use, and protect your personal information.
      </p>
      <h2>Data Collection and Usage</h2>
      <p>
        EarnFlix collects certain personal information when you sign up and use our services. This may include your name, email address, device information, and usage data such as the videos you watch and the time spent on our platform.
      </p>
      <h2>Usage Tracking and Income Calculation</h2>
      <p>
        Our system tracks the amount of time you spend on EarnFlix to calculate your income. This information is used solely for the purpose of generating your earnings and improving our services.
      </p>
      <h2>Withdrawal Process</h2>
      <p>
        You can withdraw your earnings manually through our platform. Please note that withdrawals are processed based on your recorded balance, income, and withdrawal history.
      </p>
      <h2>Data Security</h2>
      <p>
        We prioritize the security of your personal information and employ industry-standard measures to protect it from unauthorized access, disclosure, alteration, or destruction.
      </p>
      <h2>Referral Guidelines</h2>
      <p>
        To ensure uninterrupted earnings and avoid account restrictions, please adhere to our referral guidelines. Referring users from different cities is recommended to prevent potential issues.
      </p>
      <h2>Withdrawal Eligibility</h2>
      <p>
        To qualify for withdrawals, you must use EarnFlix for a minimum of 60 hours per month, equivalent to 2 hours per day. We encourage additional usage to maximize your earnings potential.
      </p>
      <h2>Single Device Policy</h2>
      <p>
        For optimal performance and to prevent account issues, please use only one device per Wi-Fi network and one device per SIM data connection.
      </p>
      <h2>Changes to Privacy Policy</h2>
      <p>
        EarnFlix reserves the right to update or modify this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>
    </div>
  );
};

export default PrivacyPolicyScreen;

import React from 'react';

const AboutUsScreen = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>
        Welcome to EarnFlix, the innovative platform that rewards you for your time spent watching videos! Our mission is to provide a unique earning opportunity while offering entertaining content from YouTube through the YouTube Data API V3.
      </p>
      <h2>Our Vision</h2>
      <p>
        At EarnFlix, we envision a world where everyone can earn extra income simply by doing what they love—watching videos. We strive to create a seamless and rewarding experience for our users while fostering a sense of community and empowerment.
      </p>
      <h2>How It Works</h2>
      <p>
        EarnFlix utilizes advanced algorithms to track the time you spend on our platform and calculates your earnings accordingly. The more time you spend watching videos, the more you can earn! With our user-friendly interface and transparent earning system, maximizing your income has never been easier.
      </p>
      <h2>Our Commitment</h2>
      <p>
        We are committed to ensuring the security and privacy of our users' personal information. EarnFlix employs industry-leading security measures to safeguard your data and offers transparent policies regarding data collection, usage, and withdrawal processes.
      </p>
      <h2>Get Started Today</h2>
      <p>
        Join EarnFlix today and start earning income while enjoying your favorite videos! Whether you're a casual viewer or a dedicated content consumer, there's something for everyone on EarnFlix. Get started now and unlock the potential of earning with every minute you spend on our platform.
      </p>
    </div>
  );
};

export default AboutUsScreen;
